import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Select,
    MenuItem
} from "@material-ui/core";
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { useStateValue } from "../../StateContext";
import CreditCard from "./PaymentType/CreditCard";

const PaymentForm = () => {

    const stripe = useStripe();
    const [{ formValues }] = useStateValue();
    const [paymentType, togglePaymnentType] = useState(1);
    const [paymentRequest, setPaymentRequest] = useState(null);

    useEffect(() => {

        const availableCountries = ["AE", "AT", "AU", "BE", "BR", "CA", "CH", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HK", "IE", "IN", "IT", "JP", "LT", "LU", "LV", "MX", "MY", "NL", "NO", "NZ", "PH", "PL", "PT", "RO", "SE", "SG", "SI", "SK", "US"];

        if (availableCountries.includes(formValues.country.code)) {
            const paymentRequest = stripe.paymentRequest({
                country: formValues.country.code,
                currency: formValues.currency.code.toLowerCase(),
                total: { label: "test", amount: formValues.amount * 100, },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API first.
            paymentRequest.canMakePayment().then(result => {
                console.log(result)
                if (result) {
                    setPaymentRequest(paymentRequest);
                }
            });
        }

    }, [formValues, stripe]);


    const SelectedPayment = ({ type }) => {
        switch (type) {
            case 1:
                return <CreditCard />
            case 2:
                return <Grid xs={12} sm={6} justify="center" style={{padding: "50px 0", margin: "auto"}}>
                    <PaymentRequestButtonElement options={{
                        paymentRequest,
                        style: {
                            paymentRequestButton: {
                                type: 'donate',
                                theme: 'dark',
                            },
                        }
                    }} />
                </Grid>
            default:
                return <CreditCard />
        }
    }

    return <>
        <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
                {paymentRequest
                    ?
                    <Select
                        value={paymentType}
                        onChange={e => togglePaymnentType(e.target.value)}
                    >
                        <MenuItem value={1}><Typography variant="h6">Credit Card</Typography></MenuItem >
                        <MenuItem value={2}><Typography variant="h6">Google Pay</Typography></MenuItem >
                    </Select>
                    :
                    <Typography variant="h6">Credit Card</Typography>
                }
            </Grid>
        </Grid>
        <SelectedPayment type={paymentType} />
        <Grid item xs={12}>
            <Typography variant="caption">
                I, being a parishioner of Church of Scientology Europe (“the Church”), am hereby making an advance donation for participation in religious services. Any contribution made by me to the Church is a donation and therefore belongs to the Church. I understand that donations to the Church are generally not refundable. The Church is not in any way under any obligation to return any portion of a religious donation I have made.
            </Typography>
        </Grid>
    </>
}

export default PaymentForm;